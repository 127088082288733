import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Layout } from '../components/Layout';
import { colors, fonts } from '../components/utilities/theme';
import { Icon } from '../components/svg/icon';

const Contact = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Layout page="contact">
        <Grid>
          <ContactInfoGrid>
            <h3>contact information</h3>
            <ContactInfo>
              <Icon name="clock" />
              <p>
                Monday - Friday
                <br />
                9:30am - 5:30pm
              </p>
            </ContactInfo>
            <ContactInfo>
              <Icon color={colors.primary} name="location" />
              <p>
                836 North Institue Street,
                <br />
                Colorado Springs, CO
                <br />
                80903
              </p>
            </ContactInfo>
            <ContactInfo>
              <Icon name="phone" />
              <p>719-633-7986</p>
            </ContactInfo>
            <ContactInfo>
              <Icon name="email" />
              <p>eonstudios@earthlink.net</p>
            </ContactInfo>
          </ContactInfoGrid>
          <MapContainer>
            <LocationHeading>
              <LocationIcon
                name="location"
                color="white"
                bgColor={colors.black}
              />
              location
            </LocationHeading>
            <Map
              frameBorder="0"
              tabIndex={0}
              src="https://www.google.com/maps/embed/v1/place?q=place_id:EjM4MzYgTiBJbnN0aXR1dGUgU3QsIENvbG9yYWRvIFNwcmluZ3MsIENPIDgwOTAzLCBVU0EiMRIvChQKEgm1wNAdDUUThxFXMPwI9xuThRDEBioUChIJl2zZcnpFE4cRsaC1m7vlHEM&key=AIzaSyARrv2xo0UCG8EiTOaVmk6CSuNh7zb0Q1c"
            ></Map>
          </MapContainer>
        </Grid>
      </Layout>
    </motion.div>
  );
};

export default Contact;

const Grid = styled.section`
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
  justify-content: space-between;
  row-gap: 5vw;

  @media (max-width: 940px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    justify-content: center;
    /* justify-items: center; */
    margin-top: 150px;
  }
`;

const ContactInfoGrid = styled.div`
  background: ${colors.black};
  display: grid;
  grid-auto-flow: row;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  border-radius: 2px;
  padding: 25px;
  row-gap: 25px;
  max-width: 440px;
  color: white;
  justify-content: center;

  @media (max-width: 940px) {
    grid-row: 3 / 4;
    max-width: 900px;
    padding: 25px 0;
    margin-top: 200px;
  }

  h3 {
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    font-family: ${fonts.primary};
    font-size: 36px;
    justify-self: center;
    margin: 0;
  }
`;

const ContactInfo = styled.div`
  font-family: ${fonts.secondary};
  font-size: 18px;

  display: grid;
  grid-template-columns: auto 1fr;
  font-weight: 500;
  column-gap: 40px;
  align-content: center;
  align-items: center;

  p {
    margin: 0;
  }
`;

const MapContainer = styled.section`
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  justify-content: center;
  justify-items: center;

  @media (max-width: 940px) {
    grid-row: 4 / 5;
    margin-top: 200px;
    margin-bottom: 100px;
  }
`;

const LocationHeading = styled.h3`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  font-family: ${fonts.primary};
  font-size: 36px;
  color: ${colors.black};
  text-transform: uppercase;
`;

const LocationIcon = styled(Icon)`
  margin-right: 5px;
`;

const Map = styled.iframe`
  width: 640px;
  height: 450px;
  border: 0;

  @media (max-width: 700px) {
    width: 100%;
    height: 450px;
  }
`;
